import React, { useState } from 'react';
import { observer } from 'mobx-react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import EditIcon from '@mui/icons-material/Edit';

import { Box, Divider } from '@mui/material';
import { Button } from 'vatix-ui/lib/components/Button';
import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { useStore } from 'utils/hooks/store';
import { DeviceDetailsResponse } from 'utils/api/types';

import PersonalMap from 'containers/UserDetails/components/PersonalMap';
import { EmptyValue } from 'containers/IncidentDetails/components/CustomFieldDisplayValue/styles';

import UserRow from 'components/UserRow';
import MapPinUser from 'components/Icons/MapPinUser';
import GridListRenderer from 'components/Grid/renderers/GridListRenderer';
import Chip from 'components/Chip';

import {
  Container,
  DevicePicture,
  DeviceRecordContainer,
  DeviceRecordRow,
  FirstColumn,
  Header,
  HeaderContainer,
  RecordRow,
  SecondColumn,
  StyledId,
  StyledName,
  MapButtonLink,
  RowValue,
} from './styles';

import EditDeviceProperties from '../EditDeviceProperties';

const UNAVAILABLE_LOCATION = 'Location not available';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <DeviceRecordRow>
    <RecordRow key={id}>
      <FirstColumn>{name}</FirstColumn>
      <SecondColumn>{children}</SecondColumn>
    </RecordRow>
  </DeviceRecordRow>
);

const DeviceInformation: React.FunctionComponent = () => {
  const {
    deviceDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as DeviceDetailsResponse;

  const [editing, setEditing] = React.useState<boolean>(false);
  const [showMap, setShowMap] = useState(details.lastLocation?.address === UNAVAILABLE_LOCATION);

  return (
    <Container>
      <HeaderContainer>
        <Header>Device Information</Header>
        {!editing && (
          <Button
            size="small"
            variant="text"
            onClick={() => setEditing((prevState) => !prevState)}
            style={{ padding: 0, fontSize: '14px' }}
          >
            <EditIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
            Edit
          </Button>
        )}
      </HeaderContainer>
      <Divider />
      <DeviceRecordContainer $editing={editing}>
        {editing ? (
          <EditDeviceProperties setEditing={setEditing} />
        ) : (
          <>
            <Row name="Device">
              <Box display="flex" flexDirection="row">
                <DevicePicture
                  src={details?.type.image}
                  alt={`Device "${details?.type.brand} ${details?.type.model}"`}
                />
                <StyledName>
                  {details?.type.brand} {details?.type.model}
                </StyledName>
                <StyledId>{` (${details?.customId})`}</StyledId>
              </Box>
            </Row>
            <Row name="ID">
              <StyledName>{details?.customId}</StyledName>
            </Row>
            <Row name="Type">
              <StyledName>
                {details?.type.brand} {details?.type.model}
              </StyledName>
            </Row>
            <Row name="Current user">{details?.user ? <UserRow user={details.user} /> : <RowValue>N/A</RowValue>}</Row>
            <Row name="Teams">
              {details?.groups && details.groups.length > 0 ? (
                <GridListRenderer
                  disableFormatting
                  maxItems={3}
                  value={details.groups}
                  rendererItem={({ value }) => (
                    <Box display="flex" flexDirection="row" gap="6px" marginRight="10px">
                      <GroupsIcon
                        htmlColor="rgba(0, 0, 0, 0.54)"
                        sx={{ width: '18px', height: '18px', marginTop: '3px' }}
                      />
                      <StyledName>{(value.value as UuidableName).name}</StyledName>
                    </Box>
                  )}
                  noMargins
                />
              ) : (
                <RowValue>N/A</RowValue>
              )}
            </Row>
            <Row name="Cost centre">
              {details?.costCentre ? (
                <RowValue>{details?.costCentre}</RowValue>
              ) : (
                <EmptyValue>N/A</EmptyValue>
              )}
            </Row>
            <Row name="Tags">
              {details?.tags && details.tags.length > 0 ? (
                <GridListRenderer
                  disableFormatting
                  maxItems={3}
                  value={details.tags}
                  rendererItem={({ value }) => (
                    <Chip
                      size="small"
                      variant="filled"
                      label={(value.value as UuidableName).name}
                      colors={{
                        background: 'rgba(7, 114, 229, 0.08)',
                        foreground: 'rgba(1, 96, 199, 1)',
                        hoverBackground: 'rgba(0, 0, 0, 0.12)',
                      }}
                      sx={{ marginRight: '6px' }}
                    />
                  )}
                  noMargins
                />
              ) : (
                <RowValue>N/A</RowValue>
              )}
            </Row>
            <Row name="Recent location">
              {details?.lastLocation && !editing ? (
                <Box display="flex" flexDirection="column">
                  {details.lastLocation.address !== UNAVAILABLE_LOCATION && (
                    <>
                      <Box display="flex" flexDirection="row">
                        <>
                          <LocationOnIcon
                            htmlColor="rgba(0, 0, 0, 0.54)"
                            sx={{ width: '18px', height: '18px', marginTop: '3px' }}
                          />
                          <StyledName>
                            {details.lastLocation.address || `${details.lastLocation.lat}, ${details.lastLocation.lng}`}
                          </StyledName>
                        </>
                      </Box>

                      <MapButtonLink
                        color="primary"
                        onClick={() => {
                          setShowMap((prevState) => !prevState);
                        }}
                      >
                        {showMap ? 'Hide map' : 'Show map'}
                      </MapButtonLink>
                    </>
                  )}
                  {details.lastLocation && showMap ? (
                    <PersonalMap
                      position={{
                        lat: Number(details.lastLocation.lat),
                        lng: Number(details.lastLocation.lng),
                      }}
                      icon={<MapPinUser />}
                    />
                  ) : null}
                </Box>
              ) : (
                <RowValue>N/A</RowValue>
              )}
            </Row>
          </>
        )}
      </DeviceRecordContainer>
    </Container>
  );
};

export default observer(DeviceInformation);
