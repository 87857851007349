import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const TasksLabel = styled.p`
  margin-left: 8px !important;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.17px;
  padding-bottom: 3px;
`;

export const StyledCircularProgress = styled(CircularProgress).attrs({
  variant: 'determinate',
  size: '16px',
  thickness: 5,
})`
  flex: 1;
`;
