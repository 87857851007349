import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 8px;
  border: 0px solid #e0e0e0;
  background: #fff;
  margin-bottom: 16px;
`;

export const MainContainerHeader = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 12px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const MainContainerDescription = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  font-style: normal;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
`;

export const SettingsRowTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  color: rgba(0, 0, 0, 0.87);
`;
