import React from 'react';
import { Switch, Radio, RadioGroup, FormControl } from '@mui/material';
import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';
import { FormAudience } from 'stores/FormBuilderSettings/FormBuilderSettings';

import { SettingsMainContainer, SettingsRow } from '../SectionMainContainer/SectionMainContainer';
import { FlexRow, StatusRow, RowLabel } from './styles';

interface RadioOptionProps {
  value: FormAudience;
  label: string;
}

const RadioOption: React.FC<RadioOptionProps> = ({ value, label }) => (
  <FlexRow>
    <Radio style={{ padding: 0 }} value={value} aria-label={label} />
    <RowLabel>{label}</RowLabel>
  </FlexRow>
);

const GeneralSettings: React.FC = () => {
  const {
    formBuilder: { formBuilderSettings },
  } = useStore();

  const updateSettings = (key: string, value: unknown): void => {
    formBuilderSettings.setSettings({ ...formBuilderSettings.settings, [key]: value });
  };

  return (
    <SettingsMainContainer title="General Settings">
      <SettingsRow title="Status">
        <StatusRow>
          <Switch
            checked={formBuilderSettings.settings.isActive}
            size="small"
            color="primary"
            onChange={() => updateSettings('isActive', !formBuilderSettings.settings.isActive)}
            aria-label="Status toggle"
          />
          <RowLabel>{formBuilderSettings.settings.isActive ? 'Active' : 'Inactive'}</RowLabel>
        </StatusRow>
      </SettingsRow>
      <SettingsRow
        title="Audience"
        tooltip={
          <ul style={{ paddingLeft: 20, fontSize: '14px' }}>
            <li>User: Form is accessible to logged-in users only.</li>
            <li>Public: Form is accessible via a public URL for submissions by non-logged-in users.</li>
            <li>Public & User: Form is accessible to both logged-in users and via public URL.</li>
          </ul>
        }
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="audience-label"
            name="audience-radio-buttons-group"
            style={{ gap: '8px' }}
            value={formBuilderSettings.settings.audience}
            onChange={(e) => updateSettings('audience', e.target.value as FormAudience)}
          >
            <RadioOption value={FormAudience.USER} label="User" />
            <RadioOption value={FormAudience.PUBLIC} label="Public" />
            <RadioOption value={FormAudience.ALL} label="Public & User" />
          </RadioGroup>
        </FormControl>
      </SettingsRow>
    </SettingsMainContainer>
  );
};

export default observer(GeneralSettings);
