import styled, { css } from 'styled-components';

export const QuestionContainer = styled.div<{ $dragOverlay?: boolean }>`
  padding: 0px 36px;
  padding-left: 0;
  margin: 12px 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;

  border: 1px solid #fff;
  transition: all 0.2s ease;

  .drag-indicator {
    visibility: hidden;
    padding: 0 8px;
  }

  .display-field-container {
    border-left: 1px solid transparent;
  }

  &:hover {
    border-radius: 8px;
    border: ${({ $dragOverlay }) => ($dragOverlay ? '1px solid #0772e5' : '1px solid #e2e4e6')};
    background: ${({ $dragOverlay }) => ($dragOverlay ? '#f1f6fc' : '#f5f5f5')};

    .drag-indicator {
      visibility: visible;
    }

    .display-field-container {
      border-left: 1px solid #e2e4e6;
    }
  }

  ${({ $dragOverlay }) =>
    $dragOverlay &&
    css`
      border: 1px solid #0772e5;
    `}
`;
