import { action, makeObservable } from 'mobx';

import { set } from 'lodash';

import { ProtectorType } from 'utils/api/types';

import FormBuilder from '../FormBuilder';

class SectionLogicStore {
  constructor(private formBuilder: FormBuilder) {
    makeObservable(this);
  }

  @action.bound
  addSection(): string {
    if (!this.formBuilder.data) return '';

    const key = this.formBuilder.getNextContainerId();
    this.formBuilder.containers.push(key);

    const defaultNewSection = {
      actions: [],
      order: [],
      properties: {},
      required: [],
      title: 'Untitled section',
      description: '',
    };

    this.formBuilder.data.order = this.formBuilder.containers;
    this.formBuilder.data.properties[key] = defaultNewSection;

    this.formBuilder.dataManager.transformDataForDisplay();
    return key;
  }

  @action.bound
  addQuestionToSection(sectionId: string): void {
    if (!this.formBuilder.data) return;

    const key = this.formBuilder.getNextContainerId();
    this.formBuilder.items = {
      ...this.formBuilder.items,
      [sectionId]: [...this.formBuilder.items[sectionId], key],
    };

    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.order`,
      this.formBuilder.items[sectionId]
    );

    const defaultNewField = {
      description: 'Untitled question',
      questionType: ProtectorType.ShortText,
    };

    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.properties.${key}`,
      defaultNewField
    );

    this.formBuilder.setCurrentEditedQuestion({ sectionId, fieldId: key });
  }

  @action.bound
  removeSection(key: string): void {
    this.formBuilder.containers = this.formBuilder.containers.filter((container) => container !== key);

    // update actions
    this.formBuilder.formAction.removeSectionActions(key);
    this.formBuilder.formAction.removeDefaultSectionAction(key);
  }

  @action.bound
  getSectionTitleAndDescription(id: string): { title: string; description: string } {
    if (!this.formBuilder.data) return { title: '', description: '' };
    return {
      title: this.formBuilder.data.properties[id].title,
      description: this.formBuilder.data.properties[id].description || '',
    };
  }

  @action.bound
  updateSectionDetails(id: string, title?: string, description?: string): void {
    if (!this.formBuilder.data) return;
    if (title !== undefined) {
      this.formBuilder.data = set({ ...this.formBuilder.data }, `properties.${id}.title`, title);
    }
    if (description !== undefined) {
      this.formBuilder.data = set({ ...this.formBuilder.data }, `properties.${id}.description`, description);
    }
  }
}

export default SectionLogicStore;
