import React from 'react';

import { Switch } from '@mui/material';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { StyledTextField } from 'components/Input/styles';

import { Row, SettingsMainContainer } from '../SectionMainContainer/SectionMainContainer';

const ConfirmationEmailNotification: React.FC = () => {
  const {
    formBuilder: { formBuilderSettings },
  } = useStore();

  const updateEmailSettings = (updates: Partial<typeof formBuilderSettings.settings.confirmationEmail>): void => {
    formBuilderSettings.setSettings({
      ...formBuilderSettings.settings,
      confirmationEmail: {
        ...formBuilderSettings.settings.confirmationEmail,
        ...updates,
      },
    });
  };

  return (
    <SettingsMainContainer
      title="Confirmation Email Notification"
      description={formBuilderSettings.getConfirmationEmailSubheading()}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '8px', marginTop: '12px' }}>
        <Switch
          checked={formBuilderSettings.settings.confirmationEmail.isActive}
          size="small"
          color="primary"
          onClick={() => updateEmailSettings({ isActive: !formBuilderSettings.settings.confirmationEmail.isActive })}
        />

        <p style={{ fontSize: '16px', margin: 0 }}>
          {formBuilderSettings.settings.confirmationEmail.isActive ? 'Enabled' : 'Disabled'}
        </p>
      </div>

      {formBuilderSettings.settings.confirmationEmail.isActive && (
        <>
          <Row title="Sender name">
            <StyledTextField
              fullWidth
              placeholder="Sender name"
              value={formBuilderSettings.settings.confirmationEmail.senderName}
              onChange={(e) => updateEmailSettings({ senderName: e.target.value })}
            />
          </Row>
          <Row title="Subject">
            <StyledTextField
              fullWidth
              placeholder="Subject"
              value={formBuilderSettings.settings.confirmationEmail.subject}
              onChange={(e) => updateEmailSettings({ subject: e.target.value })}
            />
          </Row>
          <Row title="Reply to address">
            <StyledTextField
              fullWidth
              placeholder="Reply to address"
              value={formBuilderSettings.settings.confirmationEmail.replyTo}
              onChange={(e) => updateEmailSettings({ replyTo: e.target.value })}
            />
          </Row>
          <Row
            title="Email template"
            // eslint-disable-next-line max-len
            tooltip="Add a custom message to include in the confirmation email sent to reporters after form submission. Use placeholders like {{Event Name}}, {{Type}}, {{Event ID}}, and {{Organisation Name}} to personalise the email. Placeholders will be replaced with actual data from the event."
          >
            <StyledTextField
              fullWidth
              multiline
              rows={8}
              placeholder="Email template"
              value={formBuilderSettings.settings.confirmationEmail.template}
              onChange={(e) => updateEmailSettings({ template: e.target.value })}
            />
          </Row>
        </>
      )}
    </SettingsMainContainer>
  );
};

export default observer(ConfirmationEmailNotification);
