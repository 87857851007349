import { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from 'axios';

import {
  ActivityResponse as VatixActivityResponse,
  PaginatedResponse,
  UserNameResponse,
  UuidableName,
} from 'vatix-ui/lib/utils/api/types';

import { DistributiveOmit } from '@mui/types';

import { FeedProps } from 'vatix-ui/lib/components/Feed/types';

import {
  AlertStatus,
  AmberAlertStatus,
  UserRole,
  RedAlertStatus,
  HeightUnit,
  WeightUnit,
  Gender,
  MonitoringMethod,
  AssignmentStatus,
  TaskState,
  TaskStatus,
  NotificationTarget,
  EntityState,
  EntityModules,
} from 'core/constants';
import { ActivityType, AmberAlertActivityStatus, RedAlertActivityStatus } from 'utils/stores/Activities/constants';
import { MultipleAnswerQuestionType, SectionType } from 'stores/TemplateDetails/types';
import RootStore from 'stores/Root';

import API from './api';

export interface RequestOptions {
  baseURL?: string;
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  };
  data?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  };
  headers?: {
    [k: string]: string;
  };
  authorization?: string;
  timeout?: number;
  signal?: AbortSignal;
  onUploadProgress?: (event: ProgressEvent) => void;
  cancelTokenSource?: CancelTokenSource;
  responseType?: ResponseType;
}

export interface APIRequest<T> {
  (): Promise<AxiosResponse<T>>;
  config: AxiosRequestConfig;
}

export interface FormErrors {
  nonFieldErrors?: string[];
  fieldErrors?: {
    [k: string]: string[];
  };
}

export interface RowProps<T> {
  rowData: T;
}
export interface UuidableSubject {
  uuid: string;
  subject: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMultipleAnswerQuestionType = (obj: any): obj is MultipleAnswerQuestionType =>
  obj.workflowsType === 'multi' && obj.items.enum !== undefined && obj.items.enum.length > 0;

export interface TemplateListResponse extends UuidableName {
  isActive: boolean;
  creator: UserNameResponse;
  lastUpdate: string;
}

export interface TemplateDetailsResponse extends TemplateListResponse {
  content: TemplateContent;
}

export interface LinkedTasks {
  pending: number;
  completed: number;
}

export interface LocationProperties {
  lng: string;
  lat: string;
  address: string;
}

export type AnswerDetails = { [key: string]: string | string[] | LocationProperties };

export interface AnswerStats {
  comments: number;
  tasks: number;
  files: number;
}

export interface FormTemplateContent {
  order: string[];
  properties: { [key: string]: SectionType };
}

export interface TemplateNameContent {
  name: string;
  content: FormTemplateContent;
}

export type TemplateCreateType = {
  templateVersion: string;
  name: string;
  site?: string;
  asset?: string;
};

export interface FormsDetailsResponse {
  uuid: string;
  name: string;
  reporter: UserNameResponse;
  submitted: string | null;
  isActive: boolean | null;
  location: LocationAddressResponse | null;
  linkedTasks: LinkedTaskType;
  details: AnswerDetails;
  stats: { [key: string]: AnswerStats };
  template: TemplateNameContent;
  links: Array<LinksType>;
}

export enum FormStatus {
  InProgress = 'in_progress',
  Submitted = 'submitted',
}

export type SelectedAnswerType = string | string[] | LocationProperties | boolean | null | undefined;

interface UpdatableFormData {
  details: { [key: string]: SelectedAnswerType };
  submitted: string;
  reporter: string;
  location: { address: string | null };
  name: string;
  status: FormStatus;
  site: string | null;
}

export type FormUpdateData = Partial<UpdatableFormData> & Partial<EntitiesModulesUpdateKeys>;

export interface Licenses {
  workflows: Boolean;
  protectorNotify: Boolean;
  protectorAlarms: Boolean;
  protectorIncidents: Boolean;
}

export interface UsersCreationParams {
  email: string;
  name: string;
  role: string;
  teams: { uuid: string }[];
  licenses: Licenses;
  organisation: string | undefined;
}

export interface UsersCreationInBulkParams {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: string | null;
  dateOfBirth: string | null;
  teams: Array<string> | null;
  memorableWord: string | null;
  loneWorkingLicense: boolean;
  auditsLicense: boolean;
  incidentsLicense: boolean;
  account?: string | null;
}

export type UploadUserType = {
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  role: string | null;
  dateOfBirth: string | null;
  teams: string | null;
  memorableWord: string | null;
  loneWorkingLicence: string;
  auditsLicence: string;
  incidentsLicence: string;
  account?: string | null;
};

export interface LocationResponse extends LocationCoordinateResponse {
  address: string;
  valid: boolean;
}

export interface LocationCoordinateResponse {
  uuid: string;
  lng: string;
  lat: string;
}

export interface AmberAlert {
  alertType: string;
  uuid: string;
  alertStatus: AmberAlertStatus;
  endTime: string;
  activated: string;
}
export interface LocationDetailsResponse extends LocationCoordinateResponse {
  address: string;
  occurrenceTime: string;
  user: UserNameResponse;
  amberAlert: AmberAlert | null;
}

export interface LocationHistoryResponse extends LocationCoordinateResponse {
  alertsStatus: AlertStatus;
  address: string;
}

export interface UserResponse {
  uuid: string;
  name: string;
  monitoringMethod: MonitoringMethod;
  organisation: OrganisationResponse;
}

export enum LoginStatus {
  LoggedIn = 'logged_in',
  Enable2FA = 'enable_2fa',
  Confirm2FA = 'confirm_2fa',
}

export type LoginResponse = {
  status: LoginStatus;
  user: UserProfileResponse | null;
  redirect?: string;
  type?: LoginType;
};

export enum DeviceType {
  MobileApp = 'mobile_app',
  SharedDevice = 'shared_device',
}

export interface DeviceResponse {
  uuid: string;
  phoneNumber: string;
  imei?: string;
  customId?: string;
  type?: DeviceTypeResponse;
  deviceType: DeviceType;
  tags: TagResponse[];
}

export interface SharedDeviceResponse extends DeviceResponse {
  imei: string;
  customId: string;
}

export interface CreatorData extends UuidableName {
  phoneNumber: string;
}

export interface ActivityResponse {
  uuid: string;
  type: ActivityType;
  user: UserResponse | null;
  creator: UserNameResponse | null;
  creatorData: CreatorData | null;
  alarm: RedAlertResponse | null;
  created: string;
  occurred: string;
  message: string;
  status: string;
  device: DeviceResponse | null;
  hasLocation: boolean;
  extra: null | object;
  incident: UuidableName | null;
}

export interface VoiceMemoExtra {
  fileSize: number;
  fileName: string;
}

export interface VoiceMemoActivityResponse extends ActivityResponse {
  type: ActivityType.VoiceMemo;
  user: UserResponse;
  creator: UserNameResponse;
  creatorData: null;
  device: DeviceResponse;
  extra: VoiceMemoExtra;
}

export interface RaisedRedAlertActivityResponse extends VatixActivityResponse {
  alert: RedAlertResponse;
}

export interface CreatedIncidentActivityResponse extends VatixActivityResponse {
  incident: IncidentResponse;
}

export interface IncidentActivityResponse extends VatixActivityResponse {
  incident: IncidentResponse;
  status: string;
  previousStatus: string;
}

export interface IncidentActivityAssignmentResponse extends VatixActivityResponse {
  incident: IncidentResponse;
  status: IncidentStatus;
  user: UuidableName | null;
  previousUser: UuidableName | null;
}

export interface IncidentFieldChangeResponse extends VatixActivityResponse {
  incident: IncidentResponse;
  field: string;
  fieldDescription: string;
  previous?: string;
  next?: string;
}

export interface IncidentSubjectChangeResponse extends VatixActivityResponse {
  subject: string | null;
  previousSubject: string | null;
}

export interface IncidentLocationChangeResponse extends VatixActivityResponse {
  location: LocationAddressResponse | null;
  previousLocation: LocationAddressResponse | null;
}

export interface UserAddedActivityResponse extends VatixActivityResponse {
  user: UserNameResponse;
}

export interface UserNameGenderResponse extends UserNameResponse {
  gender: Gender | null;
}

export interface DeviceAssignmentActivityResponse extends VatixActivityResponse {
  user: UserNameGenderResponse;
  device: DeviceResponse;
  status: AssignmentStatus;
}

export type ActivityListResponse = PaginatedResponse<ActivityResponse>;

export type SeparatorResponse = ActivityResponse;

export interface ActiveUserResponse {
  uuid: string;
  name: string;
  activityStatus: AlertStatus;
  location: LocationCoordinateResponse;
  redAlert: RedAlertResponse | null;
}

export interface AlertResponse {
  uuid: string;
  user: UserResponse;
  location: LocationResponse;
  activated: string;
}

export interface RedAlertResponse extends AlertResponse {
  alertStatus: RedAlertStatus;
  device: DeviceResponse | null;
}

export enum AlertType {
  AmberAlert = 'amber',
  RedAlert = 'red',
}

export interface AlarmResponse {
  uuid: string;
  name: string;
  user: UserResponse;
  duration: number | null;
  responder: UuidableName;
  alertStatus: RedAlertStatus;
  activated: string;
  location: LocationResponse;
  alertType: AlertType;
  subject: string;
}

export interface AmberAlarmResponse {
  uuid: string;
  user: UserResponse;
  location: LocationResponse;
  activated: string;
  alertStatus: AmberAlertStatus;
  endTime: string;
  alertType: AlertType;
}

export type AmberAlarmPaginatedResponse = PaginatedResponse<AmberAlarmResponse>;
export type AlarmsPaginatedResponse = PaginatedResponse<AlarmResponse>;

export interface UserAlarmDetailsResponse extends UuidableName {
  email: string;
  phoneNumber: string;
  personalDetails?: PersonalDetailsResponse;
  monitoringMethod: MonitoringMethod;
  alarmResponseProfile: AlarmResponseProfileResponse | null;
  amberAlert: AmberAlert | null;
}

export enum TriggerType {
  Button = 'button',
  ExpiredTimer = 'expired_timer',
}

export type AlarmDetailsResponse = {
  uuid: string;
  user: UserAlarmDetailsResponse;
  location?: LocationResponse;
  device?: DeviceResponse;
  alarmResponseProfile: AlarmResponseProfileResponse;
  alertStatus: RedAlertStatus;
  activated: string;
  name: string;
  duration: number | null;
  triggerType: TriggerType;
  subject: string;
};

export interface AmberAlertResponse extends AlertResponse {
  alertStatus: AmberAlertStatus;
}

export interface PendingRedAlertResponse extends RedAlertResponse {
  alertStatus: RedAlertStatus.Pending;
  ignored: boolean;
}

export interface CompletedRedAlertResponse extends RedAlertResponse {
  alertStatus: RedAlertStatus.Completed;
}

export interface AcceptedRedAlertResponse extends RedAlertResponse {
  alertStatus: RedAlertStatus.Accepted;
}

export interface ResolutionReasonResponse {
  uuid: string;
  name: string;
  requireComment: boolean;
}

export type UserBase = {
  uuid: string;
  name: string;
  role: UserRole;
  email: string;
  phoneNumber: string | null;
};

export interface EditUserResponse {
  uuid: string;
  name: string;
  role: UserRole;
  email: string;
  phoneNumber: string | null;
  organisation: OrganisationResponse;
  personalDetails: PersonalDetailsResponse | null;
  vehicleDetails: VehicleDetailsResponse[];
  contactDetails: ContactDetailsResponse[];
  tags: TagResponse[];
  showSafety: boolean;
  alarmResponseProfile: AlarmResponseProfileResponse | null;
  groups: UuidableName[];
  licenses: UserLicensesResponse;
  dateJoined: string;
}

export interface UserDetailsRedAlertResponse {
  uuid: string;
  activated: string;
  alertStatus: RedAlertStatus.Pending | RedAlertStatus.Accepted;
  device: DeviceResponse | null;
}

export interface CustomFieldResponse {
  uuid: string;
  title: string;
  value: string;
}

export interface PersonalDetailsResponse {
  height: string | null;
  heightUnit: HeightUnit;
  weight: number | null;
  weightUnit: WeightUnit;
  distinguishingMarks: string | null;
  customInformation: string;
  birthDate: string | null;
  eyeColour: string | null;
  gender: Gender | null;
  customFields: CustomFieldResponse[];
  verificationWord: string | null;
  bloodType: string;
  medicalConditions: string;
  allergies: string;
  currentMedication: string;
  costCentre: string | null;
}

export interface EscalationInstructionResponse {
  uuid: string;
  phoneNumber: string;
  contactName: string;
  description: string;
  isOrganisation: boolean;
}

export interface ContactDetailsResponse {
  uuid: string;
  type: string;
  address: string;
  phoneNumber: string | null;
  email: string;
  customInformation: string;
  customFields: CustomFieldResponse[];
}

export interface VehicleDetailsResponse {
  uuid: string;
  type: string;
  licensePlate: string;
  registeredDate: string | null;
  customInformation: string;
  customFields: CustomFieldResponse[];
}

export interface UserDetailsResponse {
  uuid: string;
  name: string;
  role: UserRole;
  email: string;
  phoneNumber: string | null;
  customId: string;
  organisation: OrganisationResponse;
  redAlert: UserDetailsRedAlertResponse | null;
  activityStatus: AlertStatus;
  personalDetails: PersonalDetailsResponse | null;
  contactDetails: ContactDetailsResponse[];
  vehicleDetails: VehicleDetailsResponse[];
  devices: SharedDeviceResponse[];
  tags: TagResponse[];
  lastLocation: LocationAddressResponse;
  alarmResponseProfile: AlarmResponseProfileResponse | null;
  showSafety: boolean;
  amberAlert: AmberAlert | null;
  dateJoined: string;
  groups: UuidableName[];
  licenses: UserLicensesResponse;
}

export type OrganisationResponse = UuidableName;

export type ActivityMapping = FeedProps<RootStore, typeof API>['mapping'];

export interface ProfileOrganisationResponse extends OrganisationResponse {
  showContacts: boolean;
  activityMap: boolean;
  accountNumber: string;
  maximumSitesAllowed: number;
  maximumAssetsAllowed: number;
  maximumSuppliersAllowed: number;
}

export interface SurveyResponse {
  hash: string;
  template: string;
}

export interface UserProfileResponse {
  uuid: string;
  name: string;
  role: UserRole;
  email: string;
  phoneNumber: string | null;
  organisation: ProfileOrganisationResponse;
  monitoringMethod: MonitoringMethod;
  twoFactorAuthEnabled: boolean;
  twoFactorAuthAllowed: boolean;
  ssoUser: boolean;
  isProtectorActive: boolean;
  isWorkflowsActive: boolean;
  licenses: UserLicensesResponse;
  showSurvey: boolean;
  survey: SurveyResponse;
  dashboards: DashboardList | null;
  appCues: boolean;
  helpScout: boolean;
  readOnly: boolean;
  intercomUserHash: string;
  showObjectManager: boolean;
}

export interface UserLicensesResponse {
  workflows: boolean;
  protectorMonitoringSelf: boolean;
  protectorMonitoringVatix: boolean;
  protectorAlarms: boolean;
  protectorNotify: boolean;
  protectorIncidents: boolean;
  events: boolean;
  risks: boolean;
}

export interface UserListItemResponse {
  uuid: string;
  name: string;
  tags: TagResponse[];
  licenses: UserLicensesResponse;
  alarmResponseProfile: AlarmResponseProfileResponse | null;
  devices: SharedDeviceResponse[];
  showSafety: boolean;
  phoneNumber: string | null;
}

interface MonitoringStats {
  arcMonitored: number;
  selfMonitored: number;
  notMonitored: number;
}

export type UserListPaginatedResponse = PaginatedResponse<UserListItemResponse> & {
  stats: MonitoringStats;
};

export interface DeviceTypeResponse {
  uuid: string;
  brand: string;
  model: string;
  image: string;
}

export interface SharedDeviceWithTypeResponse {
  uuid: string;
  type: DeviceTypeResponse;
  customId: string;
}

export interface DeviceListResponse extends SharedDeviceWithTypeResponse {
  user: UserResponse | null;
  tags: TagResponse[];
  groups: UuidableName[];
}

export type DeviceListPaginatedResponse = PaginatedResponse<DeviceListResponse> & {
  stats: MonitoringStats;
};

export type DeviceAnalyticsType = {
  date: string;
  active: number;
  inactive: number;
};

export type ChartType = {
  date: string[];
  active: number[];
  inactive: number[];
};

export interface LocationAddressResponse {
  uuid: string;
  address: string;
  lat: string;
  lng: string;
}

export type TagResponse = UuidableName;

export interface DeviceDetailsResponse {
  uuid: string;
  type: DeviceTypeResponse;
  customId: string;
  user: UserNameResponse | null;
  knownUser: boolean;
  lastPoll: string | null;
  lastLocation: LocationAddressResponse | null;
  tags: TagResponse[];
  groups: UuidableName[];
  costCentre: string | null;
}

export interface DeviceUpdateData {
  user?: string | null;
  tags?: { name: string }[];
  groups?: string[];
  costCentre?: string | null;
}

export interface PaginationParams {
  limit: number;
  offset: number;
}

export interface OrderingParams {
  ordering: string;
}

export interface UserCustomIdResponse {
  uuid: string;
  name: string;
  customId: string;
}

export interface ReportUserResponse {
  uuid: string;
  name: string;
  email: string;
  phoneNumber: string | null;
}

export interface AsyncResponse<T> {
  data?: T;
  error?: string;
}

export interface DevicesSearchResponse extends UuidableName {
  // name is custom_id, but field is renamed to keep compalibility with UuidableName type
  brand: string;
  model: string;
  image: string;
  user: string | null;
}

export interface AlarmResponseProfileSearchResponse extends UuidableName {
  escalationType: MonitoringMethod;
}

export interface AlarmResponseProfileResponse extends AlarmResponseProfileSearchResponse {
  escalationInstructions: EscalationInstructionResponse[];
  actions: EscalationAction[];
  actionFlow: ActionFlow;
}

export interface RedAlertsReportTableResponse {
  uuid: string;
  activated: string;
  completed: string | null;
  user?: ReportUserResponse;
  deviceId?: string | null;
  resolutionReason: ResolutionReasonResponse | null;
  resolutionComment: string;
}

export type RedAlertsReportTablePaginatedResponse = PaginatedResponse<RedAlertsReportTableResponse>;

export interface RelatedTaskResponse {
  uuid: string;
  assigned: UserNameResponse;
  name: string;
  status: TaskStatus;
}

export type RelatedTaskPaginatedResponse = PaginatedResponse<RelatedTaskResponse>;

export interface DeviceAssignmentResponse {
  uuid: string;
  user: UserNameResponse;
  assigned: string;
  unassigned: string | null;
}

export type DeviceHistoryPaginatedResponse = PaginatedResponse<DeviceAssignmentResponse>;

export enum IncidentType {
  CustomIncident = 'custom',
  RedAlert = 'redAlert',
  AmberAlert = 'amberAlert',
}

export enum IncidentStatus {
  Open = 'open',
  Resolved = 'resolved',
  Archived = 'archived',
}

export enum ProtectorType {
  Status = 'status',
  ShortText = 'short_text',
  LongText = 'long_text',
  SingleChoice = 'single_choice',
  MultiChoice = 'multiple_choice',
  Date = 'date',
  User = 'user',
  Location = 'location',
  Number = 'number',
  DateTime = 'date_time',
  Time = 'time',
  Image = 'image',
  Section = 'section',
  Lookup = 'lookup',
}

export const DisplayProtectorType: { [key in ProtectorType]: string } = {
  [ProtectorType.Status]: 'Status',
  [ProtectorType.ShortText]: 'Short Text',
  [ProtectorType.LongText]: 'Long Text',
  [ProtectorType.SingleChoice]: 'Single Choice',
  [ProtectorType.MultiChoice]: 'Multiple Choice',
  [ProtectorType.Date]: 'Date',
  [ProtectorType.User]: 'User',
  [ProtectorType.Location]: 'Location',
  [ProtectorType.Number]: 'Number',
  [ProtectorType.DateTime]: 'Date/Time',
  [ProtectorType.Time]: 'Time',
  [ProtectorType.Image]: 'Image',
  [ProtectorType.Section]: 'Section',
  [ProtectorType.Lookup]: 'Lookup',
};

export type TransitionTuple = [string, string];

export type JSONSchemaType = {
  type: string;
  description: string;
  protectorType: string;
  title?: string;
  name?: string;
  maximum?: number;
  minimum?: number;
  items?: {
    enum: string[];
  };
  properties: {
    [key: string]: JSONSchemaType;
  };
  order: string[];
  anyOf?: JSONSchemaAnyOfItemType[];
  contentEncoding?: string;
  enum?: string[];
  default?: string;
  protectorSettings?: {
    colors?: {
      [key: string]: {
        foreground: string;
        background: string;
      };
    };
    transitions?: {
      [key: string]: TransitionTuple[];
    };
  };
  required?: string[];
  lookupType?: EntityModules;
  editable?: boolean;
};

export type SectionProperties = {
  // sectionKey
  [key: string]: {
    // questionKey
    [key: string]: {
      constArray: string[];
    };
  };
};

export type QuestionProperties = {
  [key: string]: {
    constArray: string[];
  };
};

export type JSONSchemaAnyOfItemType = {
  if: {
    properties: SectionProperties | QuestionProperties;
  };
  then: {
    dependentRequired: {
      [key: string]: string[];
    };
  };
  else: {
    [key: string]: string[];
  };
};

export type LocationAnswerType = {
  lat: number;
  lng: number;
  address?: string;
};

export type AnswerTypes =
  | string
  | string[]
  | number
  | boolean
  | null
  | LocationAnswerType
  | {
    [key: string]: AnswerTypes;
  }
  | UuidableName;

export type AnswerType = {
  [key: string]: AnswerTypes;
};

export interface SchemaContent {
  schema: JSONSchemaType & { required: string[] };
  content: AnswerType;
}

export interface CustomIncidentTypeResponse extends SchemaContent {
  uuid: string;
  isActive: boolean;
  schema: JSONSchemaType & { required: string[] };
}

export type LinkedTaskType = {
  pending: number;
  completed: number;
  total: number;
};

export interface IncidentStatusResponse {
  schema: JSONSchemaType;
  value: string;
}

export interface JSONSchemaValue<T = string | string[] | object> {
  schema: JSONSchemaType;
  value: T;
}

export interface IncidentResponse {
  uuid: string;
  details: SchemaContent;
  originalIncident: SchemaContent;
  created: string;
  creator: UserNameResponse;
  owner: UserNameResponse | null;
  customType: { uuid: string; name: string; isActive: boolean } | string;
  definition: TemplateContent;
  incidentType: IncidentType;
  location: LocationResponse | null;
  status: IncidentStatusResponse;
  subject: string;
  description: string;
  extraData: { [key: string]: string };
  name?: string;
  incidentUid: string;
  linkedTasks?: LinkedTaskType;
  isDeleted: boolean;
  links: Array<LinksType>;
}

export type EntitiesModulesUpdateKeys = {
  site: string | null;
  asset: string | null;
  supplier: string | null;
  risk: string | null;
  event: string | null;
};

export type EnumOptions = { key: string, value: string }[]

export type FormBuilderPropertiesType = {
  [key: string]: {
    actions: FormActionsType;
    questionType?: ProtectorType;
    lookupType?: string;
    order: string[];
    title: string;
    description?: string;
    required: string[];
    properties: FormBuilderPropertiesType;
    enum?: EnumOptions;
  };
};

export type FormBuilderType = {
  order: string[];
  properties: FormBuilderPropertiesType;
};

export type FormActionType = {
  when: {
    // [questionKey, questionValue];
    equals: [string, string];
  } | boolean;
  then: { nextSection: string } | { submit: true };
};

export type FormActionsType = Array<FormActionType>;

export type EntityFormType = {
  uuid: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  rulesUpdatedAt: string;
  form: FormBuilderType;
}

export type FormToFieldMappingResponse = { question: string; field: string | null }[];

export type UpdateIncidentResponse = Partial<
  DistributiveOmit<IncidentResponse, 'owner'> & { owner: string | null } & Partial<EntitiesModulesUpdateKeys>
>;

export type IncidentWithLocationResponse = DistributiveOmit<IncidentResponse, 'location'> & {
  location: LocationResponse;
};

export type IncidentListPaginatedResponse = PaginatedResponse<IncidentResponse> & {
  stats: {
    type: {
      [k in IncidentType]: number;
    };
    status: {
      [k in IncidentStatus]: number;
    };
  };
};

interface DefinitionType {
  description: string;
  type: 'string';
}

export interface TemplateContent {
  mandatory: string[];
  order: string[];
  properties: { [key: string]: DefinitionType };
}

export type CustomIncidentType = {
  uuid: string;
  name: string;
  subject: string;
  isActive: boolean;
  created: string;
  creator: UuidableName;
  owner: UuidableName;
  definition: TemplateContent;
};

export interface VoiceMemoDetailsResponse {
  uuid: string;
  recording: string;
}

export type QueryParams = { [k: string]: string };

export interface StartDateSearchResponse {
  startDate: string;
}

export interface AmberAlertActivityResponse extends VatixActivityResponse {
  alert: AmberAlertResponse;
  status: AmberAlertActivityStatus;
}

export interface RedAlertActivityResponse extends VatixActivityResponse {
  alert: RedAlertResponse;
  creatorData?: CreatorData;
  status: RedAlertActivityStatus;
  message: string;
}

export interface EscalationProfileResponse {
  uuid: string;
  name: string;
  escalationType: MonitoringMethod;
  numberOfUsers?: number;
}

export type EscalationProfilePaginatedResponse = PaginatedResponse<EscalationProfileResponse>;

export interface EscalationInstructionError {
  key: 'contactName' | 'phoneNumber';
  message: string;
}

export interface EscalationInstruction {
  uuid?: string;
  contactName: string;
  phoneNumber: string;
  description?: string;
  errors?: EscalationInstructionError[];
  id?: number;
}

export enum EscalationActionType {
  CALL = 'call',
  EMAIL = 'email',
  PUSH_NOTIFICATION = 'push_notification',
}

export interface EscalationActionError {
  key: 'contactName' | 'email' | 'phoneNumber';
  message: string;
}

export interface EscalationAction {
  uuid?: string;
  contactName: string;
  escalationActionType: EscalationActionType;
  phoneNumber?: string;
  email?: string;
  errors?: EscalationActionError[];
  id?: number;
}

export enum ActionFlow {
  SEQUENTIAL = 'sequential',
  SIMULTANEOUS = 'simultaneous',
}

export interface EscalationProfileDetailsSelfResponse {
  actions: EscalationAction[];
  actionFlow: ActionFlow;
}

export interface EscalationProfileDetailsArcResponse {
  escalationInstructions: EscalationInstruction[];
}

export type EscalationProfileDetailsResponse = (
  | Partial<EscalationProfileDetailsSelfResponse>
  | Partial<EscalationProfileDetailsArcResponse>
) &
  EscalationProfileResponse;

export interface LicenseStatus {
  maxSeats: number;
  seatsTaken: number;
}

export interface LicensesStatusResponse {
  protectorMonitoringSelf: LicenseStatus;
  protectorMonitoringVatix: LicenseStatus;
}

export interface UpdateUserRequest {
  alarmResponseProfile: string | null;
  devices: string[];
  showSafety: boolean;
}

export interface DeleteUsersRequest {
  users: string[];
}
export interface EscalationProfileCreateResponse {
  uuid: string;
  name: string;
  escalationType: MonitoringMethod;
  escalationInstructions: EscalationInstructionResponse[];
  actions: EscalationAction[];
  actionFlow: ActionFlow;
}

export interface EscalationProfileCreateARC {
  name: string;
  escalationType: MonitoringMethod;
  actions?: EscalationAction[];
  actionFlow?: ActionFlow;
  escalationInstructions?: EscalationInstructionResponse[];
}

export type DashboardList = {
  [key: string]: UuidableName[];
};

export interface DashboardDetails {
  authKey: string;
  dashboardId: string;
  name: string;
  token: string;
}

export type TaskType = {
  name: string;
  customIncident: string | null;
  assigned: string;
  dueDate: string | null;
};
export interface Task {
  uuid: string;
  name: string;
  assigned: UserNameResponse | null;
  status: TaskStatus;
  dueDate: string | null;
  description: string;
}

export enum CSVImportColumns {
  firstName = 'First Name',
  lastName = 'Last Name',
  email = 'Email address',
  role = 'Role',
  dateOfBirth = 'Date of birth',
  phoneNumber = 'Phone Number',
  teams = 'Teams',
  memorableWord = 'Memorable Word',
  loneWorkingLicense = 'Lone Working Licence',
  auditsLicense = 'Audits Licence',
  incidentsLicense = 'Incidents Licence',
}

export type CreateTaskData = {
  name: string;
  inspection?: string;
  assigned?: string;
  dueDate?: string;
  links?: {
    entity: string;
    instance: string;
    extraData?: {
      questionId?: string;
    };
  }[];
} & EntitiesModulesUpdateKeys;

export interface InspectionTaskLinkType {
  isActive: boolean;
  question: string;
  questionId: string;
  position: number;
}

export interface InspectionTaskLinkToUpdateType {
  entity: string;
  instance: string;
  extraData?: { questionId: string } | {};
}

export type LinksType = {
  entity: string;
  instance:
  | {
    uuid: string;
    name: string;
    reporter: {
      uuid: string;
      name: string;
    };
    submitted: string;
    isActive: boolean;
  }
  | UuidableName;
  extraData?: CustomIncidentType | InspectionTaskLinkType;
};

export type EntityModuleValues = `${EntityModules}`;

export type ExtraParamsForCreatingEntity = { [key in EntityModuleValues]?: string };

export type EntityEnumsOptions = {
  key: string;
  value: string;
  active: boolean;
};

export type EntityRelatedActionsType = {
  total: number;
  completed: number;
};

export type EntityRelatedActionsTypeValue = {
  value: EntityRelatedActionsType;
}

export interface InspectionQuestionsSearchResponse {
  id: string;
  position: number;
  description: string;
}

export interface InspectionSearchResponse extends UuidableName {
  reporter: UuidableName;
  submitted: string | null;
}

export interface TemplateSearchResponse extends UuidableName {
  lastTemplateVersion: string;
}

export interface TaskListResponse extends Task {
  customIncident: CustomIncidentType;
  links: Array<LinksType>;
  lastUpdate: string;
  location: LocationAddressResponse | null;
}

export type TaskListPaginatedResponse = PaginatedResponse<TaskListResponse>;


export interface EventStateActivityResponse extends VatixActivityResponse {
  state: EntityState;
}

export interface EventOwnerChangeActivityResponse extends VatixActivityResponse {
  owner: UserNameResponse | null;
  previous_owner: UserNameResponse | null;
}

export interface EventNameChangeActivityResponse extends VatixActivityResponse {
  site: UuidableName;
  name: string | null;
  previous_name: string | null;
}

export interface StateActivityResponse extends VatixActivityResponse {
  state: TaskState;
  task: UuidableName;
}

export interface SiteStateActivityResponse extends VatixActivityResponse {
  state: EntityState;
  site: UuidableName;
}

export interface SiteManagerChangeActivityResponse extends VatixActivityResponse {
  site: UuidableName;
  manager: UserNameResponse | null;
  previous_manager: UserNameResponse | null;
}

export interface SiteNameChangeActivityResponse extends VatixActivityResponse {
  site: UuidableName;
  name: string | null;
  previousName: string | null;
}

export interface StatusActivityResponse extends VatixActivityResponse {
  status: TaskStatus;
}

export interface AssignmentActivityResponse extends VatixActivityResponse {
  user: UserNameResponse | null;
  previousUser: UserNameResponse | null;
}

export interface NotificationInstance extends UuidableName {
  subject?: string;
}

export interface NotificationType {
  uuid: string;
  instance: NotificationInstance;
  activity: string;
  target: NotificationTarget;
  creator: UuidableName;
  created: string;
  dismissed: boolean;
}

export type NotificationResponse = PaginatedResponse<NotificationType>;

export interface NotificationSettings {
  target: NotificationTarget;
  isActive: boolean;
  email: boolean;
  webApp: boolean;
  mobileApp: boolean;
}

export interface NotificationSettingsUpdateData {
  email?: boolean;
  webApp?: boolean;
  mobileApp?: boolean;
}

export type NotificationSettingsResponse = NotificationSettings[];

export interface FieldResponse {
  path: string[];
  schema: JSONSchemaType;
}

export interface TeamResponse extends UuidableName {
  admins: UuidableName[];
  numUsers: number;
}
export interface TeamAssignedUserResponse {
  uuid: string;
  name: string;
  isAdmin: boolean;
}

export interface TeamDetailsResponse {
  uuid?: string;
  name?: string;
  members?: TeamAssignedUserResponse[];
}

export enum LoginType {
  Traditional = 'traditional',
  OAuth = 'oauth',
}

export type SearchRequest = Partial<{
  query: string;
  limit: number;
  offset: number;
}>;

export type TasksSummary = {
  assignedToUser: number;
  inProgress: number;
  dueThisWeek: number;
  completedThisWeek: number;
};

export type HomeResponse = {
  tasksSummary?: TasksSummary;
  recentTasks?: TaskListResponse[];
  recentAlarms?: AlarmResponse[];
};

export type fusionAuthApplicationData = {
  applicationClientId?: string;
};

export type EntityValueType = string | number | null | {} | UuidableName | EntityEnumsOptions[];

export type EntityType = {
  [key: string]: {
    schema: JSONSchemaType;
    value: EntityValueType;
  };
};

export type EntityDetailsType = {
  layout: EntityLayoutType;
  entity: EntityType;
  createdAt: string;
  uuid: string;
  related: EntityModules[];
  relatedActions: EntityRelatedActionsType;
};

export type EntityFieldType = {
  uuid: string;
  key: string;
  protectorType: ProtectorType;
  dataType: string;
  name: string;
  description?: string;
  properties?: {
    enum?: EntityEnumsOptions[];
    // todo check if this is correct
    lookupType?: string;
  };
  editable: boolean;
  removable: boolean;
};

export type EntityPropertiesType = {
  [key: string]: {
    type: string;
    order: string[];
    title: string;
    description: string;
  };
};

export type EntityLayoutType = {
  type: string;
  order: string[];
  properties: EntityPropertiesType;
};

export type EntityCountType = {
  count: number;
};
