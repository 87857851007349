import { action, observable, runInAction, makeObservable, reaction } from 'mobx';
import { eventBus } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import API from 'utils/api';
import { isNotFound } from 'utils/api/errors';

import { EntityFieldType, EntityFormType, FormToFieldMappingResponse, ProtectorType } from 'utils/api/types';

import RootStore from '../Root';

export default class FormMappingStore {
  @observable error?: AxiosError;

  @observable rules: FormToFieldMappingResponse = [];

  @observable fields?: EntityFieldType[];

  @observable form?: EntityFormType;

  @observable lastPublished?: string;

  @observable isLoaded = false;

  @observable formId?: string;

  @observable isLoading = false;

  @observable entityType?: string;

  @observable edited = false;

  private initialRules: FormToFieldMappingResponse = [];

  rootStore: RootStore;

  api: typeof API;

  constructor(rootStore: RootStore, api: typeof API) {
    this.rootStore = rootStore;
    this.api = api;

    makeObservable(this);

    reaction(
      () => this.rules.map((rule) => ({ ...rule })),
      (currentRules) => {
        if (this.isLoaded) {
          this.edited = JSON.stringify(currentRules) !== JSON.stringify(this.initialRules);
        }
      }
    );

    eventBus.register(this);
  }

  async saveMapping(): Promise<void> {
    if (!this.entityType || !this.formId) return;
    const questions = this.form?.form.order.flatMap((section) => this.form?.form.properties[section].order);
    if (!questions) return;

    const rules: FormToFieldMappingResponse = questions.map((question) => ({
      question: question || '',
      field: this.rules.find((r) => r.question === question)?.field || null,
    }));

    const filteredRules = rules.filter(({ field }) => field !== null);

    try {
      await this.api.saveFormToFieldMapping(this.entityType, this.formId, filteredRules)();
      runInAction(() => {
        this.lastPublished = new Date().toISOString();
        this.initialRules = JSON.parse(JSON.stringify(filteredRules));
        this.edited = false;
      });
      this.rootStore.notification.enqueueSuccessSnackbar('Changes made successfully');
    } catch (error) {
      Logger.error('Failed to save form to field mapping', { error });
    }
  }

  @action.bound
  async loadDetails(entityType: string, formId: string): Promise<void> {
    if (this.isLoaded && this.formId === formId) {
      return;
    }

    this.entityType = entityType;
    this.isLoaded = false;
    this.isLoading = true;
    this.formId = formId;

    try {
      const [mappingResponse, fieldsResponse, formResponse] = await Promise.all([
        this.api.loadFormToFieldMapping(entityType, formId)(),
        this.api.loadEntityFields(entityType, { limit: 1000, offset: 0 })(),
        this.api.loadEntityForm(entityType, formId)(),
      ]);

      runInAction(() => {
        this.error = undefined;
        this.rules = mappingResponse.data;
        this.initialRules = JSON.parse(JSON.stringify(mappingResponse.data));
        this.fields = fieldsResponse.data.results;
        this.form = formResponse.data;
        this.lastPublished = formResponse.data.rulesUpdatedAt;
      });
    } catch (error) {
      runInAction(() => {
        const axiosError = error as AxiosError;
        this.error = axiosError;

        if (!isNotFound(axiosError)) {
          Logger.error(`Failed to load mapping for form ${formId}`, { error: axiosError, entityType });
        }
      });
    } finally {
      runInAction(() => {
        this.isLoaded = true;
        this.isLoading = false;
      });
    }
  }

  getFieldForQuestion(question: string): EntityFieldType | null {
    if (!this.rules || !this.fields) return null;
    const mapping = this.rules.find((m) => m.question === question);

    if (!mapping) return null;

    return this.fields.find((f) => f.key === mapping.field) || null;
  }

  updateFieldMapping(question: string, field: string | null): void {
    // if there is a rule for the question, update the field
    const rule = this.rules?.find((r) => r.question === question);
    if (rule) {
      rule.field = field;
    }

    // if there is no rule for the question, add a new rule
    if (!rule) {
      this.rules?.push({ question, field });
    }
  }

  getFieldsByType(
    type: ProtectorType
  ): {
    compatibleFields: EntityFieldType[];
    availableFields: EntityFieldType[];
  } {
    if (!this.fields) return { compatibleFields: [], availableFields: [] };

    const compatibleFields = this.fields.filter((field) => field.protectorType === type);
    const mappedFieldKeys = new Set(this.rules?.map((rule) => rule.field).filter(Boolean));

    const availableFields = compatibleFields.filter((field) => !mappedFieldKeys.has(field.key));

    return {
      compatibleFields,
      availableFields,
    };
  }

  @action.bound
  cleanup(): void {
    this.error = undefined;
    this.rules = [];
    this.fields = undefined;
    this.form = undefined;
    this.lastPublished = undefined;
    this.isLoaded = false;
    this.isLoading = false;
    this.formId = undefined;
  }
}
