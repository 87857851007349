import styled from 'styled-components';

export const PermissionsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;
`;

export const PermissionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  h1 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 143%; /* 22.88px */
    letter-spacing: 0.17px;
    margin: 0;
    padding: 0;
  }
  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.17px;
    margin: 0;
    padding: 0;
  }
`;
