import { styled } from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StatusRow = styled(FlexRow)`
  margin-bottom: 8px;
`;

export const RowLabel = styled.p`
  font-size: 16px;
  margin: 0;
`;
