import React, { useState } from 'react';

import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';

import { useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

import { Button } from 'vatix-ui/lib/components/Button';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ClearIcon from '@mui/icons-material/Clear';

import { StyledTextField } from 'components/Input/styles';

import { useStore } from 'utils/hooks/store';

import { ItemContainer } from './styles';
import { modifyInputValue } from '../../CreateEditFieldModal/types';
import { DropdownSortableItemProps } from './types';

function DropdownSortableItem({ id, editView, options, setOptions, item }: DropdownSortableItemProps): JSX.Element {
  const { notification, formBuilder } = useStore();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [name, setName] = useState<string>(item.value);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [editingName, setEditingName] = useState<boolean>(formBuilder.isDropdownOptionInEdit === item.key);
  const handleEditNameOfOption = (value: string): void => {
    setName(value);
  };

  const handleActiveChange = (): void => {
    const newData = options
      .map((opt) => (opt.key === item.key ? { ...opt, active: editView ? false : opt.active } : opt))
      .filter((opt) => editView || opt.key !== item.key);
    setOptions(newData);
  };

  const handleClickAway = (): void => {
    if (!editingName) return;
    if (name.trim() === '') {
      notification.enqueueErrorSnackbar('Dropdown option cannot be empty. Please enter a value before saving.');
      return;
    }
    setEditingName(false);
    const newData = options.map((opt) =>
      opt.key === item.key
        ? {
            ...opt,
            value: name,
            displayKey: item.displayKey.startsWith('untitled') || !editView ? modifyInputValue(name) : opt.displayKey,
          }
        : opt
    );
    setOptions(newData);
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        background: '#f9f9f9',
        borderRadius: '4px',
        border: '1px solid #ddd',
        margin: '4px 0',
      }}
    >
      <div {...attributes} {...listeners} style={{ height: '20px', cursor: 'grab' }}>
        <DragIndicatorIcon style={{ fill: '#A3A1AC', height: '20px' }} />
      </div>
      <ItemContainer>
        <ClickAwayListener touchEvent="onTouchStart" mouseEvent="onMouseDown" onClickAway={handleClickAway}>
          <div key={`${item.key}_name`} style={{ flex: 1 }}>
            {editingName ? (
              <StyledTextField
                autoFocus
                value={name}
                onChange={(e) => handleEditNameOfOption(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleClickAway();
                  }
                }}
                size="small"
                sx={{
                  '.MuiInputBase-input': {
                    fontSize: '14px !important',
                    padding: '0',
                  },
                  '.MuiInputBase-root fieldset': {
                    border: 'none !important',
                  },
                }}
              />
            ) : (
              <div
                role="button"
                tabIndex={0}
                onClick={() => {
                  setEditingName(true);
                }}
                style={{ flex: 1, fontSize: '14px' }}
              >
                {name}
              </div>
            )}
          </div>
        </ClickAwayListener>
        {!editingName && (
          <Tooltip title={item.displayKey.length > 32 ? item.displayKey : ''}>
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.38)',
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              API Name: {item.displayKey}
            </span>
          </Tooltip>
        )}
        {editView ? (
          <Button variant="text" color="primary" size="small" onClick={handleActiveChange}>
            Deactivate
          </Button>
        ) : (
          <IconButton onClick={handleActiveChange}>
            <ClearIcon htmlColor="rgba(7, 114, 229, 1)" />
          </IconButton>
        )}
      </ItemContainer>
    </div>
  );
}

export default DropdownSortableItem;
