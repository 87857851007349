import React from 'react';

import { IconButton, MenuItem, Select, Switch } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useStore } from 'utils/hooks/store';

import { Row, SettingsMainContainer } from '../SectionMainContainer/SectionMainContainer';
import { ExtraSubmissionContainer, ExtraSubmissionTitle, MenuItemSubtitle, UrlContainer } from './styles';
import { PermissionsColumn, PermissionsContainer } from '../PermissionsNotifications/styles';

interface FieldConfig {
  key: 'name' | 'email' | 'phone';
  title: string;
  description: string;
}

const FIELD_CONFIG: FieldConfig[] = [
  {
    key: 'name',
    title: 'Full name',
    description: "Collect the user's full name. This field will be required.",
  },
  {
    key: 'email',
    title: 'Email address',
    description: "Collect the user's email address. This will also be used to send confirmation emails.",
  },
  {
    key: 'phone',
    title: 'Phone number',
    description: "Collect the user's phone number. This field will be required.",
  },
];

const FieldToggle: React.FC<{
  fieldKey: FieldConfig['key'];
  config: FieldConfig;
  value: boolean;
  onChange: (value: boolean) => void;
}> = ({ fieldKey, config, value, onChange }) => (
  <PermissionsContainer key={fieldKey}>
    <Switch checked={value} size="small" color="primary" onChange={(_, checked) => onChange(checked)} />
    <PermissionsColumn>
      <h1>{config.title}</h1>
      <p>{config.description}</p>
    </PermissionsColumn>
  </PermissionsContainer>
);

const PublicSubmission: React.FC = () => {
  const {
    formBuilder: { formBuilderSettings },
  } = useStore();

  const updatePublicSubmissionSettings = (
    updates: Partial<typeof formBuilderSettings.settings.publicSubmission>
  ): void => {
    formBuilderSettings.setSettings({
      ...formBuilderSettings.settings,
      publicSubmission: {
        ...formBuilderSettings.settings.publicSubmission,
        ...updates,
      },
    });
  };

  const handleFieldChange = (fieldKey: FieldConfig['key']) => (newValue: boolean) => {
    updatePublicSubmissionSettings({
      options: {
        ...formBuilderSettings.settings.publicSubmission.options,
        [fieldKey]: newValue,
      },
    });
  };

  return (
    <SettingsMainContainer
      title="Public Submissions"
      // eslint-disable-next-line max-len
      description="Control how information is collected for public submissions. Choose whether submissions can be anonymous or if you want to ask for details such as name, email address, or phone number."
    >
      <Row title="Form URL">
        <UrlContainer>
          {/* show xxx before saving */}
          https://workspace.vatix.com/events/publicForm/xxxxxxxx-xxxxx-xxxxxx/
          <IconButton
            size="small"
            onClick={() =>
              navigator.clipboard.writeText('https://workspace.vatix.com/events/publicForm/xxxxxxxx-xxxxx-xxxxxx/')
            }
          >
            <ContentCopyIcon />
          </IconButton>
        </UrlContainer>
      </Row>

      <Row title="Submission type">
        <Select
          fullWidth
          value={formBuilderSettings.settings.publicSubmission.publicSubmissionType}
          onChange={(e) =>
            updatePublicSubmissionSettings({ publicSubmissionType: e.target.value as 'Anonymous' | 'Named' })
          }
          renderValue={(value) => <div>{value === 'Anonymous' ? 'Anonymous' : 'Ask for user information'}</div>}
        >
          <MenuItem value="Anonymous">
            <div>
              <div>Anonymous</div>
              <MenuItemSubtitle>Do not collect user information for public submissions</MenuItemSubtitle>
            </div>
          </MenuItem>
          <MenuItem value="Named">
            <div>
              <div>Ask for user information</div>
              <MenuItemSubtitle>Request details such as name, email, or phone number</MenuItemSubtitle>
            </div>
          </MenuItem>
        </Select>
      </Row>

      {formBuilderSettings.settings.publicSubmission.publicSubmissionType === 'Named' && (
        <ExtraSubmissionContainer>
          <ExtraSubmissionTitle>Fields</ExtraSubmissionTitle>
          {FIELD_CONFIG.map((config) => (
            <FieldToggle
              key={config.key}
              fieldKey={config.key}
              config={config}
              value={formBuilderSettings.settings.publicSubmission.options[config.key]}
              onChange={handleFieldChange(config.key)}
            />
          ))}
        </ExtraSubmissionContainer>
      )}
    </SettingsMainContainer>
  );
};

export default PublicSubmission;
