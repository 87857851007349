import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';
import { useRouteMatch } from 'react-router-dom';
import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { NotFound } from 'vatix-ui/lib/components/Error/Pages';

import DataError from 'vatix-ui/lib/components/Error/DataError';

import { formatDateTime } from 'vatix-ui/lib/utils/formatters/time';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { isNotFound } from 'utils/api/errors';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import EntityRelatedActionsCell from 'containers/IncidentDetails/components/EntityRelatedActionsCell';

import EntityDetailsContent from 'components/Entities/EntityDetailsContent';
import EntityDetailsActivities from 'components/Entities/EntityDetailsActivities';
// eslint-disable-next-line max-len
import EntityDetailsActivitiesFooter from 'components/Entities/EntityDetailsActivities/EntityDetailsActivitiesFooter';
import EntityDetailsHeader from 'components/Entities/EntityDetailsHeader';
import UserRow from 'components/UserRow';
import { ActivityType } from 'utils/stores/Activities/constants';

import { ActivityMapping } from 'utils/api/types';

import SiteStateActivity from './components/SiteStateActivity';
import SiteManagerChangeActivity from './components/SiteManagerChangeActivity';
import SiteNameChangeActivity from './components/SiteNameChangeActivity';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.SiteState]: SiteStateActivity,
  [ActivityType.SiteManagerChange]: SiteManagerChangeActivity,
  [ActivityType.SiteNameChange]: SiteNameChangeActivity,
} as unknown) as ActivityMapping;

const SiteDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch<{ entityId: string }>();

  const { siteDetails } = useStore();

  return (
    <DetailsLoader
      match={match}
      routeParam="entityId"
      // @ts-ignore
      store={siteDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }
        if (siteDetails.details === undefined) {
          return <NotFound />;
        }
        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }
        const {
          prefix,
          details: { entity },
        } = siteDetails;
        const siteName = entity[`${prefix}siteName`];
        const siteManager = entity[`${prefix}siteManager`];

        return (
          <Record
            navBarHeight={navBarHeight}
            Header={
              <EntityDetailsHeader
                store={siteDetails}
                title={siteName.value as string}
                columns={[
                  { name: 'Name', value: <p>{siteName.value as string}</p> },
                  {
                    name: 'Manager',
                    value: (
                      <>
                        {siteManager && siteManager.value ? (
                          <UserRow user={(siteManager.value as unknown) as UuidableName} />
                        ) : (
                          'N/A'
                        )}
                      </>
                    ),
                  },
                  {
                    name: 'Created',
                    value: <p>{formatDateTime(siteDetails.details.createdAt)}</p>,
                  },
                  {
                    name: 'Related Actions',
                    value: (
                      <EntityRelatedActionsCell
                        relatedActions={siteDetails.details.relatedActions || { total: 0, completed: 0 }}
                      />
                    ),
                  },
                ]}
              />
            }
            Content={(onError) => <EntityDetailsContent onError={onError} store={siteDetails} />}
            Activities={{
              Content: (
                <EntityDetailsActivities
                  activities={siteDetails.activities}
                  activityTypeToComponent={activityTypeToComponent}
                />
              ),
              Footer: <EntityDetailsActivitiesFooter activities={siteDetails.activities} />,
            }}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default SiteDetails;
