import React from 'react';

import { UniqueIdentifier } from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Collapse, MenuItem } from '@mui/material';

import { Add, ArrowForward } from '@mui/icons-material';

import { Button } from 'vatix-ui/lib/components/Button';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import { StyledSelect } from 'components/Input/styles';

import SortableItem from '../Items/Items';
import { SectionContainer, SectionLogic } from './styles';
import SectionHeader from './components/SectionHeader';

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const Section: React.FC<{ id: UniqueIdentifier; items: string[]; isLastSection: boolean }> = ({
  id,
  items,
  isLastSection,
}): React.ReactElement => {
  const { formBuilder } = useStore();

  const containers = formBuilder.getAllSectionTitlesAndKeys(id as string).filter(({ key }) => key !== id);

  const { setNodeRef } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  // get the default value for the section logic
  const [sectionLogic, setSectionLogic] = React.useState(formBuilder.formAction.getSectionDefaultAction(id as string));

  const updateSectionLogic = (val: string): void => {
    formBuilder.formAction.setSectionDefaultAction(id as string, val);
    setSectionLogic(val);
  };

  const [expanded, setExpanded] = React.useState(true);

  const addQuestion = (): void => {
    formBuilder.sectionLogic.addQuestionToSection(id as string);
  };

  const getValue = (): string => {
    if (sectionLogic === 'submit') {
      return 'submit';
    }

    return containers.find((container) => container.key === sectionLogic) ? sectionLogic : 'next';
  };

  return (
    <SortableContext strategy={verticalListSortingStrategy} id={id as string} items={items}>
      <SectionContainer ref={setNodeRef}>
        <SectionHeader id={id} setExpanded={setExpanded} expanded={expanded} />
        <Collapse in={expanded} key={`${id}_collapse`}>
          <div style={{ padding: expanded ? '16px' : '' }}>
            {items.map((item) => (
              <SortableItem key={item} sectionId={id} fieldId={item} />
            ))}
            <Button
              onClick={addQuestion}
              style={{
                marginLeft: '24px',
              }}
              size="small"
              variant="text"
              startIcon={<Add style={{ width: '16px', height: '16px' }} />}
            >
              Add question
            </Button>
          </div>
        </Collapse>
      </SectionContainer>
      {!isLastSection && (
        <SectionLogic>
          <p>End of the section</p>
          <ArrowForward style={{ width: '16px', height: '16px' }} />
          <StyledSelect
            $withoutBorder
            defaultValue="next"
            value={getValue()}
            onChange={(val) => {
              updateSectionLogic(val.target.value as string);
            }}
            sx={{
              maxHeight: '36px !important',
              '.MuiInputBase-input': {
                padding: '0',
                fontSize: '12px !important',
              },
            }}
          >
            <MenuItem style={{ fontSize: '12px' }} value="next">
              Continue to next section
            </MenuItem>
            {containers.map((container) => (
              <MenuItem style={{ fontSize: '12px' }} value={container.key}>
                Go to section {formBuilder.getSectionOrderId(container.key)} ({container.title})
              </MenuItem>
            ))}
            <MenuItem style={{ fontSize: '12px' }} value="submit">
              Submit form
            </MenuItem>
          </StyledSelect>
        </SectionLogic>
      )}
    </SortableContext>
  );
};

export default observer(Section);
