import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

import { MainContainer, MainContainerDescription, MainContainerHeader, SettingsRowTitle } from './styles';

export const SettingsMainContainer = ({
  title,
  description,
  children,
}: {
  title: string;
  description?: string;
  children: React.ReactNode;
}): React.ReactElement => (
  <MainContainer>
    <MainContainerHeader>{title}</MainContainerHeader>
    {description && <MainContainerDescription>{description}</MainContainerDescription>}
    {children}
  </MainContainer>
);

export const SettingsRow = ({
  title,
  tooltip,
  children,
}: {
  title: string;
  tooltip?: string | React.ReactNode;
  children: React.ReactNode;
}): React.ReactElement => (
  <>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '4px' }}>
      <SettingsRowTitle>{title}</SettingsRowTitle>
      {tooltip && (
        <Tooltip
          title={tooltip}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                background: 'rgba(97, 97, 97)',
                color: '#fff',
                fontSize: '14px',
              },
            },
          }}
        >
          <InfoIcon htmlColor="rgba(0, 0, 0, 0.54)" />
        </Tooltip>
      )}
    </div>
    {children}
  </>
);

export const Row = ({
  title,
  tooltip,
  children,
}: {
  title: string;
  tooltip?: string;
  children: React.ReactNode;
}): React.ReactElement => (
  <div style={{ marginTop: '12px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '4px' }}>
      <h1
        style={{
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: 'normal',
          margin: 0,
          padding: 0,
        }}
      >
        {title}
      </h1>
      {tooltip && (
        <Tooltip
          title={tooltip}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                background: 'rgba(97, 97, 97)',
                color: '#fff',
                fontSize: '14px',
                fontWeight: 400,
                padding: '16px',
              },
            },
          }}
        >
          <InfoIcon htmlColor="rgba(0, 0, 0, 0.54)" />
        </Tooltip>
      )}
    </div>
    {children}
  </div>
);
