/* eslint-disable max-len */
import DataMixin from 'vatix-ui/lib/utils/stores/DataMixin';

import { action, makeObservable, observable } from 'mobx';

import API from 'utils/api';
import RootStore from 'stores/Root';

const defaultTemplate = `Thank you for submitting the form! 

We have received your submission and will review it shortly. If further action or follow-up is needed, our team will contact you.

Best regards,
{{Organisation Name}}`;

export enum FormAudience {
  PUBLIC = 'public',
  USER = 'user',
  ALL = 'all',
}

type FormSettings = {
  isActive: boolean;
  audience: FormAudience;
  notifications: {
    teamManagers: boolean;
    specificUsersActive: boolean;
    specificUsers: { uuid: string }[];
  };
  confirmationEmail: {
    isActive: boolean;
    senderName: string;
    subject: string;
    replyTo: string;
    template: string;
  };
  publicSubmission: {
    publicSubmissionType: 'Anonymous' | 'Named';
    options: {
      name: boolean;
      email: boolean;
      phone: boolean;
    };
  };
};

type FormSettingsResponse = {
  isActive: boolean;
  audience: FormAudience[];
  notifications: {
    teamManagers: boolean;
    specificUsers: { uuid: string; name?: string }[];
  };
  confirmationEmail: {
    isActive: boolean;
    senderName: string;
    subject: string;
    replyTo: string;
    template: string;
  };
  publicSubmission: {
    name: boolean;
    email: boolean;
    phone: boolean;
  };
};

export default class FormBuilderSettings extends DataMixin<typeof API, RootStore> {
  @observable settings: FormSettings;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    makeObservable(this);

    this.settings = {
      isActive: true,
      audience: FormAudience.USER,
      notifications: { teamManagers: true, specificUsersActive: false, specificUsers: [] },
      confirmationEmail: {
        isActive: false,
        senderName: '',
        subject: '',
        replyTo: '',
        template: defaultTemplate,
      },
      publicSubmission: {
        publicSubmissionType: 'Anonymous',
        options: {
          name: false,
          email: false,
          phone: false,
        },
      },
    };
  }

  @action.bound
  setSettings(settings: FormSettings): void {
    this.settings = { ...this.settings, ...settings };
  }

  saveSettings(): FormSettingsResponse {
    const settings: FormSettingsResponse = {
      isActive: this.settings.isActive,
      audience: [this.settings.audience],
      notifications: {
        teamManagers: this.settings.notifications.teamManagers,
        specificUsers: this.settings.notifications.specificUsers.map((user) => ({ uuid: user.uuid })),
      },
      confirmationEmail: { ...this.settings.confirmationEmail },
      publicSubmission: {
        name: this.settings.publicSubmission.options.name,
        email: this.settings.publicSubmission.options.email,
        phone: this.settings.publicSubmission.options.phone,
      },
    };

    // todo: send settings to backend
    return settings;
  }

  getConfirmationEmailSubheading(): string {
    if (this.settings.audience === 'user') {
      return 'Enable or disable confirmation emails for the form. If this notification is enabled, we send an email to the reporter’s email address upon submission.';
    }
    if (this.settings.audience === 'public') {
      return 'Enable or disable confirmation emails for the form. If this notification is enabled, we send an email to the reporter’s email address provided in the form (if any).';
    }
    return 'Enable or disable confirmation emails for the form. If this notification is enabled, we send an email to logged-in users and public reporters, using the email address provided in the form (if any).';
  }

  getPermissionsNotificationsSubheading(): string {
    if (this.settings.audience === 'public') {
      return 'Control who gets notified and granted access to the event records when this form is submitted.';
    }
    if (this.settings.audience === 'user') {
      return 'Control who gets notified and granted access when this form is submitted. Use team-based rules, individual users, or a combination of both.';
    }
    return 'Control who gets notified and granted access when this form is submitted. Use team-based rules (not applicable to public submissions), individual users, or a combination of both.';
  }
}
