import { Gender, HeightUnit, WeightUnit } from 'core/constants';
import { formatDate } from 'utils/formatters/time';

import {
  CustomFieldResponse,
  PersonalDetailsResponse,
} from '../../utils/api/types';

export default class UserPersonalDetails {
  height = '';

  heightUnit: HeightUnit = HeightUnit.Meter;

  weight: number | null = null;

  weightUnit: WeightUnit = WeightUnit.Kilogram;

  distinguishingMarks = '';

  customInformation = '';

  birthDate: string | null = null;

  eyeColour = '';

  gender: Gender = Gender.Other;

  customFields: CustomFieldResponse[] = [];

  verificationWord = '';

  bloodType = '';

  medicalConditions = '';

  allergies = '';

  currentMedication = '';

  costCentre: string | null = null;

  constructor(data: PersonalDetailsResponse | null) {
    if (data) {
      Object.assign(this, data);
    }
  }

  get formattedBirthDate(): string {
    if (this.birthDate) {
      return formatDate(this.birthDate);
    }
    return 'N/A';
  }

  get formattedWeight(): string {
    if (this.weight !== null) {
      return `${this.weight}${this.weightUnit}`;
    }
    return 'N/A';
  }

  get formattedHeight(): string {
    if (this.height) {
      if (this.heightUnit === HeightUnit.Meter) {
        return `${this.height}${this.heightUnit}`;
      }
      if (this.heightUnit === HeightUnit.FeetInch) {
        const [feet, inches] = this.height.split('.');

        return `${feet}ft ${inches}in`;
      }
    }
    return 'N/A';
  }

  get initialValues(): PersonalDetailsResponse {
    return {
      height: this.height,
      heightUnit: this.heightUnit,
      weight: this.weight,
      weightUnit: this.weightUnit,
      distinguishingMarks: this.distinguishingMarks,
      customInformation: this.customInformation,
      birthDate: this.birthDate || '',
      eyeColour: this.eyeColour,
      gender: this.gender,
      customFields: this.customFields,
      verificationWord: this.verificationWord,
      bloodType: this.bloodType,
      medicalConditions: this.medicalConditions,
      allergies: this.allergies,
      currentMedication: this.currentMedication,
      costCentre: this.costCentre,
    };
  }
}
