import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';

const StyledRedirect = styled.div`
  color: #0772e5;
  font-size: 16px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const RelatedToIncidentValue = styled.p`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

type EntityDisplayValueProps = {
  link: string | undefined;
  path: string;
};

const EntityDisplayValue: React.FC<EntityDisplayValueProps> = ({ link, path }) => {
  const history = useHistory();

  if (link === undefined) {
    return null;
  }

  const navigateToSite = (event: React.MouseEvent): void => {
    history.push(path);
    event.stopPropagation();
  };

  return (
    <StyledRedirect onClick={navigateToSite} role="link">
      <RelatedToIncidentValue id={`linked-${path.split('/')[1]}`}>{link}</RelatedToIncidentValue>
    </StyledRedirect>
  );
};

export default EntityDisplayValue;
