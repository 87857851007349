import { observer } from 'mobx-react';
import React from 'react';

import { Autocomplete, Grid, Checkbox } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import dayjs, { Dayjs } from 'dayjs';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { SearchResponse } from 'vatix-ui/lib/utils/api/types';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import PhoneInput from 'vatix-ui/lib/components/PhoneInput';

import { Form, Formik, FormikHelpers } from 'formik';

import Logger from 'vatix-ui/lib/utils/logger/Logger';

import { trackEvent } from '@intercom/messenger-js-sdk';

import UserDetailsData from 'stores/UserDetails/UserDetailsData';

import { StyledDatePicker, StyledTextField } from 'components/Input/styles';

import API from 'utils/api';

import { UpdatedUserPayload } from 'utils/events/types';

import { postMessage } from 'utils/events/broadcast';
import { EventType } from 'utils/events/constants';

import { UserRole } from 'core/constants';

import { EditUserResponse } from 'utils/api/types';

import { formErrors } from 'utils/api/errors';

import Chip from 'components/Chip';

import { useStore } from '../../../../utils/hooks/store';
import { StyledSectionGrid, StyledRowTitle, ButtonWrapper, StyledOption } from './styles';
import { EditPersonalInformationSchema } from './types';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;

const EditRow: React.FC<{ name: string; disabled?: boolean; children: React.ReactNode; id?: string }> = ({
  name,
  disabled = false,
  children,
  id,
}): React.ReactElement => (
  <>
    <StyledSectionGrid item container xs={12} alignContent="flex-start" id={id} marginTop="16px">
      <StyledRowTitle $disabled={disabled}>{name}</StyledRowTitle>
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={12}>
      {children}
    </StyledSectionGrid>
  </>
);

export const PersonalInformation: React.FunctionComponent<{ setEditing: (val: boolean) => void }> = ({
  setEditing,
}) => {
  const {
    userDetails: { details: detailsRaw },
    notification,
    session,
  } = useStore();
  const details = detailsRaw as UserDetailsData;
  const { results, searchQuery, loading } = useSearch(API.searchGroups, undefined, { limit: 5 });

  const onUpdate = async (data: UserDetailsData, helpers: FormikHelpers<UserDetailsData>): Promise<void> => {
    const oldPhoneNumber = details.phoneNumber;
    try {
      helpers.setSubmitting(true);
      let formattedData: Partial<EditUserResponse> = {
        name: data.name,
        email: data.email,
        personalDetails: {
          ...data.personalDetails,
          birthDate: data.personalDetails.birthDate,
          verificationWord: data.personalDetails.verificationWord,
        },
        phoneNumber: data.phoneNumber,
        groups: data.groups,
      };

      if (session.user?.role === UserRole.User) {
        formattedData = {
          name: data.name,
          personalDetails: {
            ...data.personalDetails,
            birthDate: data.personalDetails.birthDate,
            verificationWord: data.personalDetails.verificationWord,
          },
          phoneNumber: data.phoneNumber,
        };
      }
      const response = await API.updateUserData(details.uuid, formattedData)();
      const payload: UpdatedUserPayload = response.data;
      postMessage(EventType.UpdatedUser, payload);
      notification.enqueueSuccessSnackbar('User details updated successfully!');
      // if user added a phone number send appcues track event
      if (data.phoneNumber && !oldPhoneNumber && session.user?.appCues) {
        window.Appcues.track('Phone number added');
      }
      if (session.hasIntercom) {
        trackEvent('Phone number added');
      }

      helpers.setStatus();
      helpers.setErrors({});
      setEditing(false);
    } catch (e) {
      notification.enqueueErrorSnackbar('Could not update user details');
      // @ts-ignore
      const { nonFieldErrors, fieldErrors } = formErrors(e);
      fieldErrors && helpers.setErrors(fieldErrors);
      nonFieldErrors && helpers.setStatus(nonFieldErrors);
      // @ts-ignore
      if (!isBadRequest(e)) {
        Logger.error('Invalid update user details API response', e);
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };
  const isUser = session.user?.role === UserRole.User;
  return (
    <Formik
      initialValues={details}
      onSubmit={(values, helpers) => onUpdate(values, helpers)}
      validationSchema={EditPersonalInformationSchema(details.showSafety)}
    >
      {({ values, handleChange, touched, errors, isSubmitting, setFieldValue }) => (
        <Form>
          <Grid container style={{ padding: '20px' }}>
            <EditRow name="Name">
              <StyledTextField
                id="name"
                name="name"
                value={values.name}
                helperText={touched.name ? errors.name : ''}
                error={(touched.name || false) && errors.name !== undefined}
                onChange={handleChange}
                fullWidth
              />
            </EditRow>

            <EditRow name="Email" disabled={isUser}>
              <StyledTextField
                id="email"
                name="email"
                value={values.email}
                helperText={touched.email ? errors.email : ''}
                error={(touched.email || false) && errors.email !== undefined}
                onChange={handleChange}
                fullWidth
                disabled={isUser}
              />
            </EditRow>
            <EditRow name="Date of birth">
              <StyledDatePicker
                sx={{ width: '100%' }}
                value={values.personalDetails.birthDate ? dayjs(values.personalDetails.birthDate as string) : null}
                // @ts-ignore
                onChange={(date: Dayjs) => {
                  setFieldValue('personalDetails.birthDate', date && dayjs(date).format('YYYY-MM-DD'));
                }}
                slotProps={{
                  field: { clearable: true },
                }}
                format="DD/MM/YYYY"
                error={(touched.personalDetails?.birthDate || false) && errors.personalDetails?.birthDate !== undefined}
                helperText={touched.personalDetails?.birthDate ? errors.personalDetails?.birthDate : ''}
              />
            </EditRow>
            <EditRow name="Phone number">
              <PhoneInput
                style={{ width: '100%' }}
                defaultCountry="gb"
                preferredCountries={['gb', 'ie', 'us']}
                regions={(['north-america', 'europe', 'middle-east'] as unknown) as [string]}
                value={values.phoneNumber}
                onChange={(e) => setFieldValue('phoneNumber', e === '+' ? null : e)}
                variant="outlined"
                disableAreaCodes
                placeholder="e.g. +44 0123456789"
                error={(touched.phoneNumber || false) && errors.phoneNumber !== undefined}
                helperText={touched.phoneNumber ? errors.phoneNumber : ''}
              />
            </EditRow>
            {isUser ? null : (
              <EditRow name="Teams">
                <Autocomplete
                  multiple
                  id="team"
                  options={results}
                  value={values.groups}
                  disableCloseOnSelect
                  renderOption={(props, option: SearchResponse, { selected }) =>
                    option.uuid === loadingOption.uuid ? (
                      <StyledOption>
                        <CenteredCircularProgress />
                      </StyledOption>
                    ) : (
                      <StyledOption {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                          checkedIcon={<CheckBoxIcon fontSize="medium" />}
                          checked={selected}
                        />
                        {option.name}
                      </StyledOption>
                    )
                  }
                  getOptionLabel={(option: SearchResponse | null) => option?.name as string}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder={values.groups.length === 0 ? 'Choose option(s)' : ''}
                      onChange={(e) => {
                        searchQuery(e.target.value);
                      }}
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip {...getTagProps({ index })} label={`${option.name}`} fontSize={14} />
                    ))
                  }
                  loading={loading}
                  noOptionsText="-"
                  onChange={(_, value) => {
                    setFieldValue('groups', value);
                    searchQuery('');
                  }}
                  isOptionEqualToValue={(option: SearchResponse | null, val: SearchResponse | null) =>
                    val?.uuid !== undefined && val?.uuid === option?.uuid
                  }
                  fullWidth
                />
              </EditRow>
            )}
            <EditRow name="Memorable word">
              <StyledTextField
                fullWidth
                id="personalDetails.verificationWord"
                name="personalDetails.verificationWord"
                value={values.personalDetails.verificationWord}
                disabled={isSubmitting}
                onChange={handleChange}
              />
            </EditRow>

            <EditRow name="Cost centre">
              <StyledTextField
                fullWidth
                id="personalDetails.costCentre"
                name="personalDetails.costCentre"
                value={values.personalDetails.costCentre}
                disabled={isSubmitting}
                onChange={handleChange}
              />
            </EditRow>

            <ButtonWrapper>
              <Button variant="outlined" onClick={() => setEditing(false)} size="large">
                Cancel
              </Button>

              <Button variant="contained" type="submit" size="large" disabled={isSubmitting}>
                Save
              </Button>
            </ButtonWrapper>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default observer(PersonalInformation);
