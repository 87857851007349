import { RouterStore } from '@superwf/mobx-react-router';

import DeviceAnalytics from 'stores/DeviceAnalytics';

import MobileAnalytics from 'stores/MobileAnalytics';

import TaskDetails from 'stores/TaskDetails';

import NotificationSettingsStore from 'stores/NotificationSettings';

import TemplateDetails from 'stores/TemplateDetails';

import FormDetails from 'stores/FormDetails';

import SiteDetailsStore from 'stores/SiteDetails';

import EntityFields from 'stores/EntityFields';

import AssetsDetailsStore from 'stores/AssetsDetails/AssetsDetails';

import SuppliersDetailsStore from 'stores/SuppliersDetails';

import FormBuilder from 'stores/FormBuilder';

import EventDetailsStore from 'stores/EventDetails/EventDetails';

import FormMappingStore from 'stores/FormMapping/FormMapping';

import { Stores } from './types';
import API from '../../utils/api';
import NotificationStore from '../Notification';
import PendingRedAlertsStore from '../PendingRedAlerts';
import SessionStore from '../Session';
import UserListStore from '../UserList';
import UIStore from '../UI';
import UserDetailsStore from '../UserDetails';
import ActivityMapStore from '../ActivityMap';
import ConfigStore from '../Config';
import DeviceListStore from '../DeviceList';
import DeviceDetailsStore from '../DeviceDetails';
import IncidentDetailsStore from '../IncidentDetails';
import AlarmDetailsStore from '../AlarmDetails';
import EscalationProfileListStore from '../EscalationProfileList';
import LicensesStore from '../Licenses';
import EscalationProfileDetailsStore from '../EscalationProfileDetailsStore';
import Inbox from '../Inbox';
import Home from '../Home';
import EntityLayout from '../EntityLayout';
import FormBuilderSettingsStore from '../FormBuilderSettings';

export default class RootStore {
  session: SessionStore;

  routing: RouterStore;

  ui: UIStore;

  userList: UserListStore;

  userDetails: UserDetailsStore;

  notification: NotificationStore;

  activityMap: ActivityMapStore;

  pendingRedAlerts: PendingRedAlertsStore;

  config: ConfigStore;

  deviceList: DeviceListStore;

  deviceDetails: DeviceDetailsStore;

  incidentDetails: IncidentDetailsStore;

  alarmDetails: AlarmDetailsStore;

  escalationProfileList: EscalationProfileListStore;

  licenses: LicensesStore;

  escalationProfileDetails: EscalationProfileDetailsStore;

  deviceAnalytics: DeviceAnalytics;

  mobileAnalytics: MobileAnalytics;

  taskDetails: TaskDetails;

  notificationSettings: NotificationSettingsStore;

  inbox: Inbox;

  home: Home;

  templateDetails: TemplateDetails;

  formDetails: FormDetails;

  siteDetails: SiteDetailsStore;

  assetDetails: AssetsDetailsStore;

  supplierDetails: SuppliersDetailsStore;

  eventDetails: EventDetailsStore;

  entityFields: EntityFields;

  entityLayout: EntityLayout;

  formBuilder: FormBuilder;

  formMapping: FormMappingStore;

  formBuilderSettings: FormBuilderSettingsStore;

  constructor() {
    this.session = new SessionStore(this, API);
    this.routing = new RouterStore();
    this.ui = new UIStore(this, API);
    this.userList = new UserListStore(this, API);
    this.userDetails = new UserDetailsStore(this, API);
    this.notification = new NotificationStore(this);
    this.activityMap = new ActivityMapStore(this, API);
    this.pendingRedAlerts = new PendingRedAlertsStore(this, API);
    this.config = new ConfigStore(this, API);
    this.deviceList = new DeviceListStore(this, API);
    this.deviceDetails = new DeviceDetailsStore(this, API);
    this.incidentDetails = new IncidentDetailsStore(this, API);
    this.alarmDetails = new AlarmDetailsStore(this, API);
    this.escalationProfileList = new EscalationProfileListStore(this, API);
    this.licenses = new LicensesStore(this, API);
    this.escalationProfileDetails = new EscalationProfileDetailsStore(this, API);
    this.deviceAnalytics = new DeviceAnalytics(this, API);
    this.mobileAnalytics = new MobileAnalytics(this, API);
    this.taskDetails = new TaskDetails(this, API);
    this.notificationSettings = new NotificationSettingsStore(this, API);
    this.inbox = new Inbox(this, API);
    this.home = new Home(this, API);
    this.templateDetails = new TemplateDetails(this, API);
    this.formDetails = new FormDetails(this, API);
    this.siteDetails = new SiteDetailsStore(this, API);
    this.assetDetails = new AssetsDetailsStore(this, API);
    this.entityFields = new EntityFields(this, API);
    this.entityLayout = new EntityLayout(this, API);
    this.supplierDetails = new SuppliersDetailsStore(this, API);
    this.formBuilder = new FormBuilder(this, API);
    this.eventDetails = new EventDetailsStore(this, API);
    this.formMapping = new FormMappingStore(this, API);
    this.formBuilderSettings = new FormBuilderSettingsStore(this, API);
  }

  get stores(): Stores {
    return {
      root: this,
      session: this.session,
      routing: this.routing,
      ui: this.ui,
      userList: this.userList,
      userDetails: this.userDetails,
      notification: this.notification,
      activityMap: this.activityMap,
      pendingRedAlerts: this.pendingRedAlerts,
      config: this.config,
      deviceList: this.deviceList,
      deviceDetails: this.deviceDetails,
      incidentDetails: this.incidentDetails,
      alarmDetails: this.alarmDetails,
      escalationProfileList: this.escalationProfileList,
      licenses: this.licenses,
      escalationProfileDetails: this.escalationProfileDetails,
      deviceAnalytics: this.deviceAnalytics,
      mobileAnalytics: this.mobileAnalytics,
      taskDetails: this.taskDetails,
      notificationSettings: this.notificationSettings,
      inbox: this.inbox,
      home: this.home,
      templateDetails: this.templateDetails,
      formDetails: this.formDetails,
      siteDetails: this.siteDetails,
      assetDetails: this.assetDetails,
      entityFields: this.entityFields,
      entityLayout: this.entityLayout,
      supplierDetails: this.supplierDetails,
      formBuilder: this.formBuilder,
      eventDetails: this.eventDetails,
      formMapping: this.formMapping,
      formBuilderSettings: this.formBuilderSettings,
    };
  }
}
