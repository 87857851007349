/* eslint-disable max-len */
import * as React from 'react';

function CopyIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.7895 3H5.68421C4.75789 3 4 3.75789 4 4.68421V16.4737H5.68421V4.68421H15.7895V3ZM18.3158 6.36842H9.05263C8.12632 6.36842 7.36842 7.12632 7.36842 8.05263V19.8421C7.36842 20.7684 8.12632 21.5263 9.05263 21.5263H18.3158C19.2421 21.5263 20 20.7684 20 19.8421V8.05263C20 7.12632 19.2421 6.36842 18.3158 6.36842ZM18.3158 19.8421H9.05263V8.05263H18.3158V19.8421Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default CopyIcon;
