import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from 'utils/hooks/store';

import GeneralSettings from './components/GeneralSettings';
import PermissionsNotifications from './components/PermissionsNotifications';
import ConfirmationEmailNotification from './components/ConfirmationEmailNotifications';
import PublicSubmission from './components/PublicSubmission';

const FormBuilderSettings: React.FC = () => {
  const {
    formBuilder: { formBuilderSettings },
  } = useStore();

  return (
    <>
      <GeneralSettings />
      {formBuilderSettings.settings.audience !== 'user' && <PublicSubmission />}
      <PermissionsNotifications />
      <ConfirmationEmailNotification />
    </>
  );
};

export default observer(FormBuilderSettings);
