import { styled } from 'styled-components';

export const MenuItemSubtitle = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  padding: 0;
`;

export const UrlContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f8f9fa;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: space-between;

  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  button {
    margin-left: 8px;
    min-width: 32px;
    padding: 4px;
    color: #0772e5;

    &:hover {
      background: rgba(7, 114, 229, 0.08);
    }
  }
`;

export const ExtraSubmissionContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: #f5f5f5;
  margin-top: 4px;
`;

export const ExtraSubmissionTitle = styled.h1`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
  padding: 0;
`;
