import styled from 'styled-components';
import { Tabs, Tab } from '@mui/material';

export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 194px;

  @media (max-width: 1280px) {
    margin: 0 97px;
  }
`;

export const StyledTabs = styled(Tabs)`
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
`;

export const StyledTab = styled(Tab)`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.4px;
  text-transform: capitalize;
  min-height: initial;
`;
