import styled from 'styled-components';

export const FormNameTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 4px;
`;
