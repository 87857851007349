import React from 'react';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import Grid, { defaultOperators, defaultFormatters } from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import EntityRelatedActionsCell from 'containers/IncidentDetails/components/EntityRelatedActionsCell';

import { EntityRelatedActionsTypeValue } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import EntityActionCell from 'components/Entities/EntityActionCell';

import { AssetsTableProps } from './types';
import { StyledCreated } from './styles';

export const basicAssetsColumns = ([
  {
    field: 'assets__assetName',
    headerName: 'Name',
  },
  {
    field: 'relatedActions',
    headerName: 'Actions',
    renderCell: ({ value }: { value: EntityRelatedActionsTypeValue }) => (
      <EntityRelatedActionsCell relatedActions={value.value || { total: 0, completed: 0 }} />
    ),
    valueFormatter: ({ value }: { value: EntityRelatedActionsTypeValue }) =>
      `completed:${value?.value?.completed};total:${value?.value?.total}`,
    nativeFilter: true,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    renderCell: ({ value }: GridValueGetterParams) => (
      <StyledCreated>{formatDateForDisplay(value.value)}</StyledCreated>
    ),
    filterOperators: defaultOperators.date_time(),
    valueFormatter: defaultFormatters.date,
    nativeFilter: 'createdAt',
  },
] as unknown) as ColumnDefinition[];

const AssetsTable: React.FunctionComponent<AssetsTableProps> = ({ onError, onRefreshCallback }): React.ReactElement => {
  const { routing, assetDetails } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.assets.details, { entityId: item.id }));
  };

  return (
    <Grid
      basicColumns={
        ([
          ...basicAssetsColumns,
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            ariaLabel: 'actions_cell',
            // eslint-disable-next-line react/no-unused-prop-types
            renderCell: ({ row, colDef }: { row: { uuid: string }; colDef: ColumnDefinition }) => (
              <EntityActionCell rowData={row} actions={colDef.actions} store={assetDetails} />
            ),
            filterOperators: null,
            sortable: false,
          },
        ] as unknown) as ColumnDefinition[]
      }
      entity="assets"
      onError={onError}
      dataURL="/api/entities/assets/"
      fieldsURL="/api/entities/assets/fields/"
      customNoRowsText="No assets have been created yet"
      onRowClick={onRowClick}
      onRefreshCallback={onRefreshCallback}
      disableSelectAllColumns
      onlyBasicExport={false}
    />
  );
};

export default AssetsTable;
