import React from 'react';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import Grid, { defaultOperators, defaultFormatters, defaultRenderers } from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import EntityRelatedActionsCell from 'containers/IncidentDetails/components/EntityRelatedActionsCell';

import { EntityRelatedActionsTypeValue } from 'utils/api/types';

import { UserRole } from 'core/constants';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import EntityActionCell from 'components/Entities/EntityActionCell';

import { ExtendedUserOperators } from 'components/Grid/operators/User/User';

import { EventsTableProps } from './types';
import { StyledCreated } from './styles';

export const basicEventsColumns = ([
  {
    field: 'events__eventName',
    headerName: 'Name',
  },
  {
    field: 'events__eventType',
    headerName: 'Type',
  },
  {
    field: 'events__eventReporter',
    headerName: 'Reporter',
    renderCell: defaultRenderers.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    filterOperators: ExtendedUserOperators(),
    sortable: false,
  },
  {
    field: 'events__eventOwner',
    headerName: 'Owner',
    renderCell: defaultRenderers.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    filterOperators: ExtendedUserOperators(),
    sortable: false,
  },
  {
    field: 'relatedActions',
    headerName: 'Actions',
    renderCell: ({ value }: { value: EntityRelatedActionsTypeValue }) => (
      <EntityRelatedActionsCell relatedActions={value.value || { total: 0, completed: 0 }} />
    ),
    valueFormatter: ({ value }: { value: EntityRelatedActionsTypeValue }) =>
      `completed:${value?.value?.completed};total:${value?.value?.total}`,
    nativeFilter: true,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    renderCell: ({ value }: GridValueGetterParams) => (
      <StyledCreated>{formatDateForDisplay(value.value)}</StyledCreated>
    ),
    filterOperators: defaultOperators.date_time(),
    valueFormatter: defaultFormatters.date,
    nativeFilter: 'createdAt',
  },
] as unknown) as ColumnDefinition[];

const EventsTable: React.FunctionComponent<EventsTableProps> = ({ onError, onRefreshCallback }): React.ReactElement => {
  const { session, routing, eventDetails } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.events.details, { entityId: item.id }));
  };

  return (
    <Grid
      basicColumns={
        ([
          ...basicEventsColumns,
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            ariaLabel: 'actions_cell',
            // eslint-disable-next-line react/no-unused-prop-types
            renderCell: ({ row, colDef }: { row: { uuid: string }; colDef: ColumnDefinition }) => (
              <EntityActionCell rowData={row} actions={colDef.actions} store={eventDetails} />
            ),
            filterOperators: null,
            sortable: false,
          },
        ] as unknown) as ColumnDefinition[]
      }
      entity="events"
      onError={onError}
      dataURL="/api/entities/events/"
      fieldsURL="/api/entities/events/fields/"
      customNoRowsText="No events have been created yet"
      onRowClick={onRowClick}
      onRefreshCallback={onRefreshCallback}
      disableSelectAllColumns
      onlyBasicExport={session.user?.role === UserRole.User}
    />
  );
};

export default EventsTable;
