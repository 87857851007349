import { MenuItem } from '@mui/material';
import styled from 'styled-components';

export const MainRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px;
  gap: 8px;
  min-height: 40px;
  max-height: 40px;
`;

export const Item = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  padding: 0px 12px;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  max-height: 40px;
  min-height: 40px;

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
`;
